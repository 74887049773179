const applyButtons = document.querySelectorAll("[data-apply]");
const applyPopup = document.querySelector("#apply-popup");
const popupRole = document.querySelector("#popupRole");
const popupClose = document.querySelector("#popupClose");

applyPopup.addEventListener("click", (event) => {
  if (event.target === applyPopup) {
    applyPopup.classList.add("hidden");
  }
}); 

popupClose.addEventListener("click", (event) => {
  event.preventDefault();
  applyPopup.classList.add("hidden");
  document.querySelector("#sppiner").classList.remove("hidden");
  document.querySelector("#apply-popup .grid").classList.add("hidden");
  document.querySelector("#formFields").innerHTML='';
  
});

// Add click event listener to each apply button
applyButtons.forEach(function (button) {
  button.addEventListener("click", function (e) {
    e.preventDefault();
    // Read data attributes from the button or its parent
    var jobId = this.getAttribute("data-jobid"); // Assuming 'data-jobid' is a custom attribute
    var title = this.getAttribute("data-apply"); // Using 'data-apply' attribute for the title

    var xhr = new XMLHttpRequest();
    xhr.open('GET', '/index.php?p=manatal/get-popup-form&jobid='+jobId, true);
    xhr.onload = function() {
      if (xhr.status >= 200 && xhr.status < 300) {
        var responseData = xhr.responseText;
        document.querySelector("#sppiner").classList.add("hidden");
        document.querySelector("#apply-popup .grid").classList.remove("hidden");
        var formField=document.querySelector("#formFields");
        formField.innerHTML='';
        formField.innerHTML=responseData;

        // Initiate File Upload
        if (window.actionFileUpload) {
          window.actionFileUpload();
        }
      } else {
        console.error('Request failed with status:', xhr.status);
      }
    };

    xhr.onerror = function() {
      console.error('Request failed');
    };
    xhr.send();

    // Now, set these values in the popup form fields
    var popup = document.getElementById("apply-popup");
    var jobIdField = popup.querySelector('input[name="jobId"]');
    var titleField = popup.querySelector('input[name="title"]'); // Assuming you have such a field

    // Check if the elements exist before setting their values
    if (jobIdField) jobIdField.value = jobId;
    if (titleField) titleField.value = title;

    // Show the popup
    popup.classList.remove("hidden");
  });
});
