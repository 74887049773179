

function addFilesToInput(newFiles, inputId) {
  const input = document.getElementById(inputId);
  if (!input || !newFiles) {
    console.error("Invalid input ID or files.");
    return;
  }

  const dataTransfer = new DataTransfer();

  if (input.files) {
    for (let file of input.files) {
      dataTransfer.items.add(file);
    }
  }

  if (newFiles instanceof FileList) {
    for (let file of newFiles) {
      dataTransfer.items.add(file);
    }
  } else if (newFiles instanceof File) {
    dataTransfer.items.add(newFiles);
  } else {
    console.error("newFiles must be a File or FileList.");
    return;
  }

  input.files = dataTransfer.files;
  console.log(input.files)
}


const fileUpload = (el) => {
  let uploadButton = el.querySelector(".upload-button");
  let error = el.querySelector(".error");
  const fileDisplay = el.querySelector(".fileDisplay");
  const fileStore = `${el.dataset.store || 'filestore'}`

  if (uploadButton) {
    uploadButton.addEventListener('change', (event) => {
      const files = event.target.files;
      addFilesToInput(files, fileStore);

      if (files.length) {
        Array.from(files).forEach(file => {
          fileHandler(file, file.name, file.type);
        });
      }
    });
  }
  const fileHandler = (file, name, type) => {
    const supportedTypes = [
      "image",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    if (!supportedTypes.includes(type) && !type.includes("image")) {
      error.innerText = "Please upload an image, PDF, or Word document";
      return;
    }

    if (file.size > 10000000) {
      error.innerText = "File size is too large. Please upload a file less than 10MB";
      return;
    }


    error.innerText = "";
    let fileContainer = document.createElement("div");
    fileContainer.classList.add("file-preview");

    if (type.includes("image")) {
      let reader = new FileReader();
      reader.onloadend = () => {
        handleFileType(fileContainer);
        fileContainer.innerHTML += `<p class="file-name">${name}</p>`;
        fileDisplay.appendChild(fileContainer);
      };
      reader.readAsDataURL(file);
    } else {
      handleFileType(fileContainer);
      fileContainer.innerHTML += `<p class="file-name">${name}</p>`;
      fileDisplay.appendChild(fileContainer);
    }
  };

  const handleFileType = (container) => {
    let fileIcon = document.createElement("span");
    fileIcon.className = "file-icon";
    fileIcon.innerText = "📄";
    container.appendChild(fileIcon);
  };
};

export default fileUpload;