import Search from "./Search";

const JobSearch = (el) => {

  const queryParams = new URLSearchParams(window.location.search);

  const searchInputDefault = queryParams.get('search') || ''; 

  const activate = (element) => {
    element.classList.add("font-bold");
  }
  
  const deactivate = (element) => {
    element.classList.remove("font-bold");
  }

  const search = new Search({el, endpoint: '/api/job-search', activateElement: activate, deactivateElement: deactivate});
  search.addEventListeners();

  if (searchInputDefault) {
    search.searchInput.value = searchInputDefault;
    search.update();
  }
}

export default JobSearch