// Imports
import "core-js/stable";
import "regenerator-runtime/runtime";
import "./app.scss";
import "./js/dropdown";
import "./js/applyPopup";
import FileUpload from "./js/FileUpload";
import JobSearch from "./js/jobSearch";
import jobListSearch from "./js/jobListSearch";
import Slider from "./js/Slider";
import lazySizes from "lazysizes";
import AOS from "aos";

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}

function runActions() {
  action("Slider", Slider);
  action("job-search", JobSearch);
  action("job-list-search", jobListSearch);
  action("file-upload", FileUpload);

  // Animate On Scroll Library
  AOS.init();

  // Toggle 
  const menuButton = document.querySelector(".menu-btn");
  const mobileNavigation = document.querySelector(".mob-nav");
  menuButton?.addEventListener("click", function () {
    mobileNavigation.classList.toggle("open");
  });
}

runActions();

window.actionFileUpload = function() {
  action("file-upload", FileUpload);
};

// Only used in dev
if (module.hot) {
  module.hot.accept();
}
