import Search from "./Search";

const jobListSearch = (el) => {

  const activateElement = (element) => {
    element.parentElement.parentElement.querySelector(".dropdown-label").innerHTML = element.innerHTML;
    element.parentElement.classList.add("hidden");
  }
  const deactivateElement = (element) => {
    element.parentElement.classList.add("hidden");
  }

  const search = new Search({el, endpoint: '/api/job-search', activateElement, deactivateElement});
  search.addEventListeners();
}

export default jobListSearch