window.addEventListener("load", function () {
  const dropdowns = document.querySelectorAll(".dropdown");

  dropdowns.forEach((dropdown) => {
    const toggle = dropdown.querySelector(".dropdown-toggle");
    const label = toggle.querySelector(".dropdown-label");
    const content = dropdown.querySelector(".dropdown-content");
    const items = content.querySelectorAll("li");

    // Toggle dropdown content
    toggle.addEventListener("click", () => {
      if (!content.classList.contains("hidden")) {
        content.classList.add("hidden"); // Close it if open
      } else {
        closeAllDropdowns(); // Close other dropdowns
        content.classList.remove("hidden"); // Open the clicked dropdown
      }
    });

    // // Close dropdown when an item is selected
    // items.forEach(item => {
    //     item.addEventListener('click', () => {
    //         label.textContent = item.textContent;
    //         content.classList.add('hidden');
    //     });
    // });
  });

  // Close all dropdowns function
  function closeAllDropdowns() {
    dropdowns.forEach((dropdown) => {
      dropdown.querySelector(".dropdown-content").classList.add("hidden");
    });
  }

  // Close dropdowns when clicked outside
  document.addEventListener(
    "click",
    (event) => {
      if (!event.target.matches(".dropdown, .dropdown *")) {
        closeAllDropdowns();
      }
    },
    true
  );
});
