export default class Search  {
  state = {}
  sortState = ''

  activateElement = () => {
    console.log('No activate element function defined');
  }

  deactivateElement = () => {
    console.log('No deactivate element function defined');
  }

  constructor({el, endpoint, activateElement, deactivateElement}) {
    this.container = el.querySelector("#search-container");
    this.sortBy = el.querySelector("#search-sort-by")
    this.allFilters = el.querySelectorAll("[data-filter-type]")
    this.searchInput = el.querySelector("#search-input");

    this.activateElement = activateElement;
    if (deactivateElement) this.deactivateElement = deactivateElement;

    this.loadingIndicator = el.querySelector("#search-loading");
    this.apiEndpoint = endpoint;

    this.typingTimer;
    this.doneTypingInterval = 300;

    if (!this.allFilters) {
      console.error('No filter objects found');
      return;
    }

    this.allFilters.forEach(filter => {
      const filterType = filter.dataset.filterType;
      if (!this.state[filterType]) {
        this.state[filterType] = [];
      }
    });

    this.sortBy?.addEventListener("change", () => {
      this.sortState = this.sortBy.value;
      this.update();
    });

    this.searchInput?.addEventListener("keyup", () => {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(() => {
        console.log('done typing', this.searchInput.value)
        this.update();
      }, this.doneTypingInterval);
    });

    this.searchInput?.addEventListener("keydown", () => {
      clearTimeout(this.typingTimer);
    });
  }

  addEventListeners() {
    this.allFilters.forEach((filter) => {
      filter.addEventListener("click", () => {
        this.toggleFilter(filter.dataset.filterType, filter.dataset.slug, filter.dataset.singleSelect === 'true');
      });
    });
  }

  toggleFilter(key, value, isSingleSelect) {
    const index = this.state[key].indexOf(value);
    if (isSingleSelect) {
      this.state[key] = index > -1 ? []:[value];
    } else {
      if (index > -1) {
        this.state[key].splice(index, 1);
      } else {
        this.state[key].push(value);
      }
    }
    this.update();
  }

  updateUIState() {
    this.allFilters.forEach((filter) => {
      const isActive = this.state[filter.dataset.filterType].includes(filter.dataset.slug);
      if (isActive) {
        this.activateElement(filter)
      } else {
        this.deactivateElement(filter)
      }
    });
  }

  update() {
    this.loadingIndicator.classList.remove("hidden");
    const url = new URL(this.apiEndpoint, window.location.origin);
    Object.keys(this.state).forEach(key => {
      this.state[key].forEach(value => {
        url.searchParams.append(key + '[]', value);
      });
    });

    url.searchParams.append('sort', this.sortState);
    url.searchParams.append('search',  this.searchInput?.value || '');
    

    this.container.innerHTML = '';
    console.log(url);
    fetch(url)
      .then(response => response.text())
      .then(data => {
          this.container.innerHTML = data;
          this.loadingIndicator.classList.add("hidden");
          this.updateUIState(this.state);
      });
    }
}